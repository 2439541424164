import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import CsvDialog from "../../Utils/CsvDialog";
import { history } from "../../Helpers/history";
import Row from "react-bootstrap/Row";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import { DataCell, ModuleTable } from "../../Utils/List/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import { AutoCompleteUnControlled } from "../../Utils/FormInputs/AutoCompleteInput";
// import { history } from "../../Helpers/history";
// import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
// import TextField from "../../Utils/FormInputs/TextField";
// import { DataCell, ModuleTable } from "../../Utils/List/CollapsibleList";
// import TableRow from "@material-ui/core/TableRow";
import styled from "@emotion/styled/macro";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TableCell from "@material-ui/core/TableCell";
import { LoadingGif } from "../../Utils/Loader";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import { useCorrectBatchInconsistence } from "./hooks/useCorrectInconsistence";
import { CustomPagination } from "../../Utils/Tables/CustomTable";
import { BackButton } from "../../Utils/FormInputs/BackButton";
import { MuiPagination } from "../../Utils/Tables/CustomTable";
import { useMuiPagination } from "../../Containers/Dasboard/DashboardTables";

const headData = [
  { item: "Trans. Date", align: "left", isRequired: true },
  { item: "Quantity", align: "center" },
  { item: "Unit Price", align: "right" },
  { item: "Reason", align: "center" },
  { item: "Batch number", align: "center" },
  { item: "Trans. Type", align: "center" },
  { item: "New Batch", align: "center" },
  { item: "Amount", align: "center" },
  // { item: "Action", align: "right" },
];

const Container = styled.div({
  padding: "15px 120px 15px 70px",
});

export const StyledRemoveIcon = styled(RemoveCircleIcon)({
  width: "0.9em",
  height: "0.9em",
  fill: "#c4495c",
});

const StyledInput = styled(TextField)(
  {
    display: "inline",
    borderRadius: "0.7rem",
    fontSize: "13px",
  },
  ({ width }) => ({ width: width })
);

const StyledSelect = styled(SelectInput)(
  {
    display: "inline",
    borderRadius: "0.7rem",
    fontSize: "13px",
  },
  ({ width }) => ({ width: width })
);

const CorrectInconsistence = ({ actions, snackbars, match: { params } }) => {
  const {
    batches,
    batchInformation,
    isPending,
    isResolved,
    isLoading,
    isSuccess,
    isError,
    handleCloseBar,
    batchList,
    submitted,
    handleSubmit,
    page,
    total_count,
    handleChangePage,
    handleBatchSelectChange,
    selectedBatchID,
  } = useCorrectBatchInconsistence(actions, params);

  const { open, message, variant } = snackbars;
  console.log(total_count);
  return (
    <div>
      <ReusableDashboardHeader
        component="Correct Inconsistent Batches"
        heading="Correct Inconsistent Batches"
        subHeading="Correct Inconsistent Batches"
        link={history.location.pathname}
      />

      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <Container>
        <div className="mb-5">
          <BackButton text="Back" to="/inconsistence-batches" />
        </div>
        <form
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <div className=" mt-2 px-2">
            <div className="mt-2">
              <h6>
                Product Name:{" "}
                <span data-testid="product_name">
                  <b> {batchInformation.product_name}</b>
                </span>
              </h6>
              <h6>
                Batch No.:{" "}
                <span data-testid="batch_no">
                  <b> {batchInformation.batch_number}</b>
                </span>
              </h6>
              <h6>
                Quantity:{" "}
                <span data-testid="quantity">
                  <b> {batchInformation.quantity}</b>
                </span>
              </h6>
            </div>
          </div>
          {isLoading ? (
            <div className="text-center">
              <LoadingGif />
            </div>
          ) : null}
          {isSuccess || isResolved ? (
            <ModuleTable headData={headData}>
              {batches.map((row, idx) => {
                return (
                  <TableRow key={idx}>
                    <DataCell
                      align="left"
                      data-testid={"transaction_date-" + idx}
                    >
                      {row.transaction_date}
                    </DataCell>
                    <DataCell
                      align="center"
                      width="200"
                      data-testid={"quantity-" + idx}
                    >
                      {row.quantity}
                    </DataCell>
                    <TableCell align="center" data-testid={"unit_price-" + idx}>
                      {row.unit_price}
                    </TableCell>
                    <TableCell align="center" data-testid={"reason-" + idx}>
                      {row.reason}
                    </TableCell>
                    <DataCell
                      align="center"
                      data-testid={"batch_number-" + idx}
                    >
                      {row.batch_number}
                    </DataCell>
                    <DataCell
                      align="center"
                      data-testid={"transaction_type-" + idx}
                    >
                      {row.transaction_type}
                    </DataCell>
                    <DataCell align="center" data-testid={"new_batch-" + idx}>
                      <StyledSelect
                        width="130%"
                        defaultOption="Select...."
                        data-testid={"batch-" + idx}
                        options={batchList?.map((item) => {
                          return {
                            value: item.batch_id,
                            text: item.batch_id,
                          };
                        })}
                        value={selectedBatchID[idx] ?? { value: "", text: "" }}
                        name="batch_number"
                        onChange={(event) =>
                          handleBatchSelectChange(event, idx)
                        }
                      />
                    </DataCell>
                    <DataCell align="right" data-testid={"amount-" + idx}>
                      {row.amount}
                    </DataCell>
                  </TableRow>
                );
              })}
            </ModuleTable>
          ) : (
            <div>
              <p className="text-center mt-2">
                There are no batches for this product
              </p>
            </div>
          )}

          {isError ? (
            <div className="text-center">
              The server did not return a valid response
            </div>
          ) : null}
          {!isResolved || submitted ? (
            <div className="text-right">
              <button
                type="submit"
                disabled={isPending}
                data-testid="save_batch"
                className="btn btn-sm pharmacy-btn"
                onClick={handleSubmit}
              >
                {isPending ? "Submitting...." : "Submit"}
              </button>
            </div>
          ) : null}
        </form>
        <CustomPagination
          data={batches}
          records={10}
          activePage={page}
          total_count={total_count}
          handleChangeNextPage={handleChangePage}
        />
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrectInconsistence);
