import { useEffect, useRef, useState } from "react";
import { convertDate, titleCase } from "../../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { history } from "../../../Helpers/history";
import {
  useEditPurchaseData,
  usePurchaseOrderData,
} from "./usePurchaseUpdateData";
import { useAddMedicine } from "../../Medicine/CustomHooks/useAddMedicine";
import { useAddManufacturer } from "../../Manufacturer/CustomHooks/useAddManufacturer";
import { v4 as uuidv4 } from "uuid";
import { validateCurrentDate } from "../../../Utils/ConvertDate";
import { useDraftPurchaseData } from "./usePurchaseUpdateData";

export const useAddPurchase = (actions, ids) => {
  const { local_purchase_order_id, purchase_id, draft_id } = ids;
  const [rows, setRows] = useState([
    {
      product_name: "",
      product_id: "",
      draft_id: "",
      uuid_id: uuidv4(),
      batch_id: "",
      expiry_date: "",
      total_product: "",
      selectProduct: false,
      expiry_limit: 0,
      product_quantity: "",
      product_rate: "",
      total_price: "",
      discount: "",
      expiry_limit_date: "",
      disableQuantity: false,
      disableUnitCost: false,
      maximum_stock: 0,
      disableExpiry: true,
      showProduct: false,
      isValidBatch: null,
      isWithinExpiryLimit: false,
      isOverMaxStock: false,
    },
  ]);
  const [manufacturer_name, setManufacturerName] = useState({
    manufacturer_name: "",
    manufacturer_id: "",
  });
  const [state, setState] = useState({
    purchase_date: convertDate(),
    invoice_no: "",
    details: "",
    payment_type: 1,
    bank_id: "",
    expected_payment_date: "",
  });
  const [grand_total, setGrandTotal] = useState(0);
  const [purchaseCsv, setPurchaseCsv] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [allPurchases, setAllPurchases] = useState([]);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [submitCsv, setSubmitCsv] = useState(false);
  const [supplier, setSupplier] = useState({
    manufacturer_name: "",
    mobile: "",
    address: "",
    notes: "",
  });
  const csvLink = useRef();
  const { purchase, products } = useEditPurchaseData(actions, purchase_id);
  const { orderData, manufacturer } = usePurchaseOrderData(
    actions,
    local_purchase_order_id
  );
  const { purchaseData, suppliers } = useDraftPurchaseData(actions, draft_id);
  const [purchaseConfirmDialog, setConfirmationDialog] = useState(false);
  const {
    productProps,
    openProductDialog,
    handleCloseProductDialog,
    handleOpenProductDialog,
  } = useAddMedicine(actions, null, true);
  const { manufacturerProps, isResolved } = useAddManufacturer(actions);
  const [isBlocking, setIsBlocking] = useState(false);
  const [payment_method, setPaymentMethod] = useState('')
  const [paid_amount, setPaidAmount] = useState(0)

  useEffect(() => {
    actions.manufacturerActions.getAllManufacturers();
  }, [isResolved]);

  useEffect(() => {
    if (!(purchase_id || local_purchase_order_id || draft_id)) {
      return;
    }
    if (purchase_id) {
      const {
        payment_type,
        invoice_no,
        purchase_date,
        details,
        manufacturer_name: man_name,
        grand_total,
      } = purchase;

      setState({ purchase_date, invoice_no, details, payment_type });
      setManufacturerName(man_name);
      setGrandTotal(grand_total);
      setRows(products);
    }
    if (local_purchase_order_id) {
      setManufacturerName({
        manufacturer_name: manufacturer.label,
        manufacturer_id: manufacturer.value,
      });
      setRows(orderData);
      calculateGrandTotal(orderData);
    }
    if (draft_id) {
      const {
        payment_type,
        invoice_no,
        purchase_date,
        expected_date,
        details,
        manufacturer_name: man_name,
        manufacturer_id,
        grand_total,
      } = suppliers;
      setState({
        purchase_date,
        expected_payment_date: expected_date,
        invoice_no,
        details,
        payment_type,
      });
      setManufacturerName({
        manufacturer_name: man_name,
        manufacturer_id,
      });
      setGrandTotal(grand_total);
      setRows(purchaseData);
    }
  }, [
    purchase,
    products,
    orderData,
    purchaseData,
    suppliers,
    manufacturer,
    purchase_id,
    local_purchase_order_id,
    draft_id,
  ]);
  const handleChangeSupplier = (event) => {
    const { name, value } = event.target;
    setSupplier({ ...supplier, [name]: value });
    setIsBlocking(true);
  };

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    setIsBlocking(true);
  };

  const handleOpenSupplierDialog = () => {
    setOpenSupplier(true);
  };

  const handleCloseSupplierDialog = () => {
    setOpenSupplier(false);
  };

  const handleChangePaymentMethod = (event) =>{
    setPaymentMethod(event.target.value)
  }

  const handleChangePaidAmount = (event) =>{
    setPaidAmount(event.target.value)
  }

  const handleSaveDraft = (event) => {
    if (event) {
      event.preventDefault();
    }
    // event.preventDefault();
    const user = JSON.parse(sessionStorage.getItem("user"));
    const singleUser = !user ? {} : user;
    const user_roles_id = !singleUser.user_id ? "" : singleUser.user_id;

    // event.preventDefault();
    const { purchase_date, invoice_no, details, expected_payment_date } = state;
    setSubmitted(true);
    const dataArr = rows.map((item) => {
      const expiry_limit = item.isExpiryLimit
        ? { expiry_limit: +item.expiry_limit }
        : null;
      return {
        product_id: item.product_id,
        draft_id: +draft_id,
        details_id: +item.details_id,
        manufacturer_price: +item.product_rate,
        total_amount: +item.total_price,
        discount: 0,
        batch_id: item.batch_id,
        expiry_date: item.expiry_date,
        quantity: +item.product_quantity,
        ...expiry_limit,
      };
    });

    const lpo_id = local_purchase_order_id ? { local_purchase_order_id } : null;
    const pur_id = purchase_id ? { purchase_id } : null;
    const dra_id = draft_id ? { draft_id: +draft_id } : null;

    const validate_purchase_date = validateCurrentDate(purchase_date);

    const data = {
      manufacturer_id: manufacturer_name.manufacturer_id,
      purchase_date: purchase_date,
      user_roles_id,
      purchase_details: details,
      payment_type: 1,
      ...lpo_id,
      ...pur_id,
      ...dra_id,
      expected_payment_date,
      grand_total_amount: +grand_total,
      chalan_no: invoice_no,
      purchased_products: dataArr,
      payment_method: +payment_method,
      paid_amount: +paid_amount
    };

    const arr =
      rows.length > 0 &&
      rows.every(
        (item) =>
          item.product_id &&
          item.batch_id &&
          // item.expiry_date &&
          item.product_quantity &&
          item.product_rate &&
          // item.expiry_date > purchase_date &&
          item.isValidBatch === null
      );
    if (
      arr &&
      invoice_no &&
      manufacturer_name &&
      expected_payment_date &&
      validate_purchase_date
    ) {
      setIsSubmitted("pending");
      setIsBlocking(false);
      // const url = purchase_id ? 'purchase_update' : 'insert_purchase'
      axios
        .post(
          `${config.epharmUrl}/Cpurchase/insert_or_update_purchase_draft`,
          data
        )
        .then(() => {
          actions.snackbarActions.successSnackbar("Draft Saved Successfully");
          setIsSubmitted("resolved");
          history.push("/drafted_purchase");
        })
        .catch((error) => {
          errorMessages(error, null, actions);
          setIsSubmitted("rejected");
        });
      // }
    }
  };

  const handleSubmitPurchase = (event) => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const singleUser = !user ? {} : user;
    const user_roles_id = !singleUser.user_id ? "" : singleUser.user_id;
    if (event) {
      event.preventDefault();
    }
    const { purchase_date, invoice_no, details, expected_payment_date } = state;
    setSubmitted(true);
    const dataArr = rows.map((item) => {
      const expiry_limit = item.isExpiryLimit
        ? { expiry_limit: +item.expiry_limit }
        : null;
      return {
        product_id: item.product_id,
        manufacturer_price: +item.product_rate,
        total_amount: +item.total_price,
        discount: 0,
        batch_id: item.batch_id,
        expeire_date: item.expiry_date,
        expiry_date: item.expiry_date,
        quantity: +item.product_quantity,
        ...expiry_limit,

      };
    });

    const lpo_id = local_purchase_order_id ? { local_purchase_order_id } : null;
    const pur_id = purchase_id ? { purchase_id } : null;
    const dra_id = draft_id ? { draft_id: +draft_id } : null;

    const validate_purchase_date = validateCurrentDate(purchase_date);

    const data = {
      manufacturer_id: manufacturer_name.manufacturer_id,
      purchase_date: purchase_date,
      user_roles_id,
      purchase_details: details,
      payment_type: 1,
      ...lpo_id,
      ...pur_id,
      ...dra_id,
      expected_payment_date,
      grand_total_amount: +grand_total,
      chalan_no: invoice_no,
      purchased_products: dataArr,
      payment_method : +payment_method,
      paid_amount: +paid_amount
    };

    const arr =
      rows.length > 0 &&
      rows.every(
        (item) =>
          item.product_id &&
          item.batch_id &&
          item.expiry_date &&
          item.product_quantity &&
          item.product_rate &&
          item.expiry_date > purchase_date &&
          item.isValidBatch === null
      );

    const required_cash = (payment_method && +paid_amount && +paid_amount > 0 && +paid_amount <= +grand_total ) || ! (payment_method && paid_amount)
    if (
      arr &&
      invoice_no &&
      manufacturer_name &&
      expected_payment_date &&
      validate_purchase_date &&
      required_cash
    ) {
      setIsSubmitted("pending");
      setIsBlocking(false);
      // const url = purchase_id ? 'purchase_update' : 'insert_purchase'
      axios
        .post(`${config.epharmUrl}/Cpurchase/insert_purchase`, data)
        .then(() => {
          actions.snackbarActions.successSnackbar(
            "Purchase Saved Successfully"
          );
          setIsSubmitted("resolved");
          history.push("/managepurchase");
        })
        .catch((error) => {
          errorMessages(error, null, actions);
          setIsSubmitted("rejected");
        });
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmationDialog(false);
  };

  const handleChangeManufacturer = (value) => {
    setManufacturerName(value);
    setOpenAlert(false);
    setIsBlocking(true);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setRows(arr);
    setIsBlocking(true);
  };

  const handleChangeBatchId = (event, index) => {
    const { value } = event.target;
    // const rule = new RegExp('^[a-zA-Z0-9_.-]*$')
    // const isValid = rule.test(value)
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return { ...item, batch_id: value };
      }
      return item;
    });
    setRows(arr);
    setIsBlocking(true);
  };
  const handleBlur = (event, index) => {
    let { name, value } = event.target;
    if (name === "expiry_date") {
      // Regular expression to match the date format MM/DD/YYYY
      const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

      // Check if the entered value matches the date format
      if (!dateRegex.test(value)) {
        return;
      }
    }
  };

  const retrieveExpiry = (b_id, idx) => {
    const rowsCopy = [...rows];
    if (b_id && rows[idx]["product_id"]) {
      axios
        .get(`${config.epharmUrl}/Cinvoice/retrieve_product_batch_id`, {
          params: { batch_id: b_id, product_id: rows[idx]["product_id"] },
        })
        .then((response) => {
          const data = response.data;
          const dt = data ? data : {};
          if (data) {
            if (dt.expire_date) {
              rowsCopy[idx]["expiry_date"] = dt.expire_date;
              rowsCopy[idx]["disableExpiry"] = true;
            } else {
              rowsCopy[idx]["expiry_date"] = "";
              rowsCopy[idx]["disableExpiry"] = false;
            }
            setRows(rowsCopy);
          }
        })
        .catch((error) => {
          rowsCopy[idx]["expiry_date"] = "";
          rowsCopy[idx]["disableExpiry"] = false;
          setRows(rowsCopy);
          errorMessages(error, null, actions);
        });
    }
    setIsBlocking(true);
  };

  const handleAddRow = () => {
    const item = {
      product_name: "",
      product_id: "",
      batch_id: "",
      expiry_date: "",
      total_product: "",
      product_quantity: "",
      product_rate: "",
      total_price: "",
      discount: "",
      selectProduct: false,
      disableExpiry: true,
      showProduct: false,
      isValidBatch: null,
      uuid_id: uuidv4(),
    };
    setRows([...rows, item]);
    setIsBlocking(true);
  };

  const handleRemoveSpecificRow = (uuid_id_to_remove) => () => {
    const arr = rows.filter((item) => item.uuid_id !== uuid_id_to_remove);
    calculateGrandTotal(arr);
    setRows(arr);
    setIsBlocking(true);
  };

  const calculateGrandTotal = (arr) => {
    const sum = arr.reduce((a, row) => {
      return +(+a + +row.total_price);
    }, 0);
    setGrandTotal(sum);
  };

  const handleSearchProduct = (eventTxt, index, isEmpty) => {
    const rowsCopy = [...rows];
    if (rowsCopy[index]) {
      rows[index]["product_name"] = eventTxt;
    }
    if (isEmpty) {
      rowsCopy[index]["product_id"] = "";
      rowsCopy[index]["batch_id"] = "";
      rowsCopy[index]["expiry_date"] = "";
      rowsCopy[index]["expiry_limit"] = 0;
      rowsCopy[index]["total_product"] = 0;
      rowsCopy[index]["product_quantity"] = "";
      rowsCopy[index]["product_rate"] = "";
      rowsCopy[index]["total_price"] = 0;
      rowsCopy[index]["discount"] = 0;
      rowsCopy[index]["selectProduct"] = false;
      rowsCopy[index]["disableExpiry"] = true;
    }
    let temp = eventTxt.trim();
    if (temp.includes("(")) {
      temp = eventTxt.substring(0, eventTxt.indexOf("("));
    }
    if (temp !== "") {
      actions.purchaseActions.searchDrugbyManufacturer(titleCase(temp));
      rowsCopy[index]["selectProduct"] = false;
      rowsCopy[index]["showProduct"] = true;
    } else {
      actions.purchaseActions.searchDrugbyManufacturer("");
      rowsCopy[index]["showProduct"] = false;
    }
    setRows(rowsCopy);
    setIsBlocking(true);
  };

  const retrieveProductData = (event, index) => {
    const rowsCopy = [...rows];
    if (event.value === "" || event.innerText === "") {
      return;
    }

    if (rowsCopy[index]) {
      rowsCopy[index]["product_name"] = event.innerText;
      if (rowsCopy[index]["product_id"] !== event.value) {
        rowsCopy[index]["batch_id"] = "";
        rowsCopy[index]["expiry_date"] = "";
        rowsCopy[index]["product_quantity"] = "";
        rowsCopy[index]["product_rate"] = "";
        rowsCopy[index]["total_price"] = 0;
        rowsCopy[index]["discount"] = 0;
        rowsCopy[index]["disableExpiry"] = true;
      }
      rowsCopy[index]["product_id"] = event.value;
      rowsCopy[index]["selectProduct"] = false;
      axios
        .get(`${config.epharmUrl}/Cinvoice/retrieve_product_data`, {
          params: { product_id: event.value },
        })
        .then((response) => {
          const data = response.data;
          const dt = data ? data : {};
          rowsCopy[index]["total_product"] = dt.total_product
            ? dt.total_product
            : 0;
          rowsCopy[index]["expiry_limit"] = dt.expiry_limit
            ? dt.expiry_limit
            : 0;
          rowsCopy[index]["maximum_stock"] = dt.maximum_stock
            ? dt.maximum_stock
            : 0;
          setRows(rowsCopy);
        })
        .catch((error) => {
          errorMessages(error, null, actions);
        });
    }
    rowsCopy[index]["showProduct"] = false;
    setIsBlocking(true);
  };

  const handleChangeExpiryLimit = (event, index) => {
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          expiry_limit: event.target.value,
          isExpiryLimit: true,
        };
      }
      return item;
    });
    setRows(arr);
    setIsBlocking(true);
  };

  const handleChangeQuantity = (event, index) => {
    const { value } = event.target;
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          product_quantity: value,
          total_price: +value * +item.product_rate,
        };
      }
      return item;
    });
    setRows(arr);
    calculateGrandTotal(arr);
    setIsBlocking(true);
  };
  const handleChangeAmount = (event, index) => {
    const { value } = event.target;
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          total_price: value,
          product_rate: +value / +item.product_quantity,
        };
      }
      return item;
    });
    setRows(arr);
    calculateGrandTotal(arr);
    setIsBlocking(true);
  };

  const handleChangePrice = (event, index) => {
    const { value } = event.target;
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          product_rate: value,
          total_price: +value * +item.product_quantity,
        };
      }
      return item;
    });
    setRows(arr);
    calculateGrandTotal(arr);
    setIsBlocking(true);
  };

  const handleCloseBar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleUploadCsv = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("upload_csv_file", purchaseCsv);
    setSubmitCsv(true);
    if (purchaseCsv) {
      actions.purchaseActions.uploadCsv(formData);
    }
  };

  const handleChangePurchaseCsv = (event) => {
    if (
      event.target.files[0].name.substr(
        event.target.files[0].name.length - 4
      ) === ".csv"
    ) {
      setPurchaseCsv(event.target.files[0]);
    } else {
      event.target.value = null;
      actions.snackbarActions.errorSnackbar("Please upload only csv format");
    }
  };

  const fetchData = () => {
    axios
      .get(`${config.epharmUrl}/Cpurchase/getallpurchases`)
      .then((response) => {
        const data = response.data;
        const dt = data ? data : {};
        const list = dt.purchase_list ? dt.purchase_list : [];
        setAllPurchases(list);
        csvLink.current.link.click();
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  };

  const saveNewManufacturer = (event) => {
    event.preventDefault();
    const { manufacturer_name, address, mobile, notes } = supplier;
    axios
      .post(`${config.epharmUrl}/Cmanufacturer/insert_manufacturer`, null, {
        params: { manufacturer_name, address, mobile, details: notes },
      })
      .then(() => {
        actions.snackbarActions.successSnackbar("Supplier Saved Successfully");
        actions.manufacturerActions.getAllManufacturers();
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
    setOpenDialog(false);
  };

  const handleProductOnBlur = (prdt_name, idx) => {
    const rowsCopy = [...rows];
    if (prdt_name) {
      rowsCopy[idx]["disableUnitCost"] = false;
      rowsCopy[idx]["disableQuantity"] = false;
    } else {
      rowsCopy[idx]["disableUnitCost"] = true;
      rowsCopy[idx]["disableQuantity"] = true;
    }
    setRows(rowsCopy);
    setIsBlocking(true);
  };

  const isPending = isSubmitted === "pending";
  const isResolvedPurchase = isSubmitted === "resolved";
  const isPendingDraft = isSubmitted === "pendingDraft";

  return {
    rows,
    manufacturer_name,
    state,
    grand_total,
    purchaseCsv,
    openAlert,
    openDialog,
    submitted,
    isSubmitted,
    allPurchases,
    openSupplier,
    submitCsv,
    supplier,
    handleChangeSupplier,
    handleChangeValue,
    handleOpenSupplierDialog,
    handleCloseSupplierDialog,
    handleSubmitPurchase,
    handleChangeManufacturer,
    handleChange,
    retrieveExpiry,
    handleAddRow,
    handleRemoveSpecificRow,
    handleSearchProduct,
    retrieveProductData,
    handleChangeQuantity,
    handleChangePrice,
    handleCloseBar,
    handleOpenDialog,
    handleCloseDialog,
    handleUploadCsv,
    handleChangePurchaseCsv,
    fetchData,
    saveNewManufacturer,
    csvLink,
    handleChangeBatchId,
    purchaseConfirmDialog,
    handleCloseConfirmDialog,
    handleChangeExpiryLimit,
    handleProductOnBlur,
    productProps,
    openProductDialog,
    handleOpenProductDialog,
    handleCloseProductDialog,
    manufacturerProps,
    handleChangeAmount,
    isBlocking,
    isResolvedPurchase,
    isPendingDraft,
    isPending,
    handleSaveDraft,
    handleBlur,
    payment_method,
    paid_amount,
    handleChangePaidAmount,
    handleChangePaymentMethod
  };
};
