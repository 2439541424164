import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";


function useCorrectBatchInconsistence(actions, params = {}) {
  const { product_id: prdt_id, product_name: prdt_name, batch_id: bat_id,new_batch:new_bat } = params;
  const [batches, setBatches] = useState([]);
  const [batchList, setBatchList] = useState([])
  const [batchInformation,setBatchInformation] = useState({})
  const [product, setProduct] = useState({
    product_name: "",
    product_id: prdt_id,
    selected_product: prdt_name,
    batch_id: bat_id,
    new_batch:new_bat

  });
  const [loading, setLoading] = useState("idle");
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [selectedBatchID,setSelectedBatchID] = useState("")
  const [submitted,setSubmitted] = useState(false)
  const [isBlocking, setIsBlocking] = useState(false);
  const [total_count,setTotalCount] = useState()
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const { product_id,batch_id } = product;

  useEffect(() => {
      axios.post(
          `${config.epharmUrl}/Cinvoice/correct_batch_inconsistencies?product_id=${product_id}&batch_number=${batch_id}&per_page=${perPage}&page=${page}`).then(response => {
          const res = response.data;
          const dt = !res.data ? {} : res.data;
          const arr = dt.stock_transactions ? dt.stock_transactions : [];
          const per_page = dt.pagination ?? dt.pagination
          const count = per_page.total_count ?? res.total_count
          setLoading("success");
          setBatchInformation(dt)
          setBatches(arr);
          setTotalCount(count)
      }).catch(error => {
          errorMessages(error, null, actions);
          setLoading(false)
      })
  }, [])

  useEffect(() => {
    axios.post(
        `${config.epharmUrl}/Cinvoice/retrieve_product_data_inv?product_id=${product_id}`).then(response => {
        const res = response.data;
        const dt = !res.batch ? {} : res.batch;
        setLoading("success");
        setBatchList(dt)
        
    }).catch(error => {
        errorMessages(error, null, actions);
        setLoading(false)
    })
}, [product_id])

const handleChangeItem = (event, idx, batch_idx) => {
  const { name, value } = event.target;
  const arr = batchList.map((item, index) => {
    if (index === idx) {
      const arr = item.batches.map((batch, batch_index) => {
        if (batch_index === batch_idx) {
            return { ...batch, [name]: value };
          }
        else
        return batch;
      });
      return { ...item, batches: arr };
    }
    return item;
  });
  setBatchList(arr);
};

const handleBatchSelectChange = (event, rowIndex) => {
  const { value } = event.target;
  setSelectedBatchID((prevSelectedBatchIDs) => ({
    ...prevSelectedBatchIDs,
    [rowIndex]: value,
  }));
};

const handleSubmit = (event) => {
  event.preventDefault();
  const json = batches.map((item,index) => {
   return { product_id: product_id,
    old_batch: item.batch_number,
    new_batch: selectedBatchID[+index] ,
    stock_id: item.id}
   }).filter((item) => item.new_batch)

    setSubmitted(true);
    setIsSubmitted("pending");
    setIsBlocking(false);
    axios
      .post(`${config.epharmUrl}/Cinvoice/change_batch_no`,json)
      .then((res) => {
        const dt = !res.data ? {} : res.data;
        const dat = dt.data ?? {}
        const count = dat.pagination.total_count ?? ""
        const arr = dat.stock_transactions ? dat.stock_transactions : [];
       
        setBatchInformation(dat)
        setBatches(arr);
        setTotalCount(count)
        actions.snackbarActions.successSnackbar(
          "Batch updated Successfully"
        );
        setIsSubmitted("resolved");
        setSelectedBatchID("")
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setIsSubmitted("rejected");
      });

};

const handleChangePage=(newPage) =>{
  setPage(newPage)
}

  const handleCloseBar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const isPending = isSubmitted === "pending";
  const isResolved = isSubmitted === "resolved";
  const isRejected = isSubmitted === "rejected";

  const isLoading = loading === "loading";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  return {batches,batchInformation,isPending,isRejected,isResolved,isLoading,isSuccess,isError,handleCloseBar,batchList,
  handleChangeItem,submitted,handleSubmit, handleBatchSelectChange,selectedBatchID,page,total_count,handleChangePage,
  total_count} 
}
export { useCorrectBatchInconsistence };
