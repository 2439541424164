import React from "react";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import styled from "@emotion/styled/macro";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import { Link } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1500px",
    margin: "10px auto",
    width: "90%",
  },
  list: {
    paddingTop: 0,
  },
  listItem: {
    borderBottom: "1px dashed #e5dddd",
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(14, 118, 186, .08)",
    },
  },
  primary: {
    fontSize: "0.875rem",
    color: "#0e76ba",
  },
  secondary: {
    fontSize: "0.85em",
  },
  section: {
    maxWidth: "350px",
    marginBottom: "35px",
  },
  h6: {
    // borderBottom: '2px solid #027a5f',
    fontSize: "1.15em",
    color: "#4e4848",
  },
  icon: {
    // color: '#464545',
    width: ".8em",
  },
  span: {
    marginRight: "2px",
  },
  listItemIcon: {
    minWidth: "26px",
  },
  listIcon: {
    width: ".75em",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ReportsContainer = styled.div({
  padding: "40px 80px",
});

const PurchaseAndSalesReports = () => {
  const classes = useStyles();

  const list = [
    {
      id: 1,
      title: "Inventory",
      icon: <LocalMallOutlinedIcon classes={{ root: classes.icon }} />,
      children: [
        { name: "Product Trends", url: "/product_trends", id: "401" },
        { name: "Expired Products", url: "/expired_drugs", id: "404" },
        { name: "Stock Report", url: "/stockreport", id: "403" },
        { name: "Aging Report", url: "/aging_report", id: "405" },
        { name: "Out of Stock", url: "/out_of_stock", id: "405" },
        { name: "Stock Summary", url: "/stocksummary", id: "201" },
        { name: "Product History Report", url: "/product_history", id: "402" },
      ],
    },
    {
      id: 2,
      title: "Financial Reports",
      icon: <ShoppingCartOutlinedIcon classes={{ root: classes.icon }} />,
      children: [
        { name: "Sales Report", url: "/sales_report", id: "201" },
        {
          name: "Sales Report(Medicine Wise) ",
          url: "/sales_report_medicine_wise",
          id: "202",
        },
        { name: "Purchase Report", url: "/purchase_report", id: "401" },
      ],
    },

    // {
    //   id: 3,
    //   title: 'Accounting Reports',
    //   icon: <AccountBalanceWalletOutlinedIcon classes={{ root: classes.icon }} />,
    //   children: [
    //     { name: 'Profit & Loss Statement', url: '/report/profit_loss_statement', id: '201' },
    //     { name: 'Balance Sheet', url: '/report/balancesheet', id: '201' },
    //     { name: 'Trial Balance', url: '/report/trialbalance', id: '201' },
    //     // {name: 'Customer Receivables', url: '/accounting/debtors', id: '201'},
    //     // {name: 'Accounts Payable', url: '/creditors', id: '201'},
    //   ]
    // },
    {
      id: 4,
      title: "Payables",
      icon: <ReceiptOutlinedIcon classes={{ root: classes.icon }} />,
      children: [
        { name: "Bills", url: "/creditors/report", id: "201" },
        // {name: 'Payables Summary', url: '/creditorssummary', id: '201'},
        // {name: 'Payments Made', url: '/creditorssummary', id: '201'},
      ],
    },

    {
      id: 5,
      title: "Receivables",
      icon: <SupervisorAccountOutlinedIcon classes={{ root: classes.icon }} />,
      children: [
        {
          name: "Customer Balances",
          url: "/accounting/debtors/report",
          id: "201",
        },
        // {name: 'Receivables Summary', url: '/debtorssummary', id: '201'},
      ],
    },
  ];

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Reports"
        heading="Reports"
        subHeading="Reports"
        link="/allreports"
      />
      <ReportsContainer>
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className="col-lg-4">
              <div className={classes.section}>
                <h6 className={classes.h6}>
                  <span className={classes.span}>{item.icon}</span>
                  {item.title}
                </h6>
                {item.children.map((it) => (
                  <List classes={{ root: classes.list }} key={it.id}>
                    <Link to={it.url} style={{ textDecoration: "none" }}>
                      <ListItem classes={{ root: classes.listItem }}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                          <StarBorderOutlinedIcon
                            classes={{ root: classes.listIcon }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={it.name}
                          classes={{ primary: classes.primary }}
                        />
                      </ListItem>
                    </Link>
                    {Array.isArray(it.children)
                      ? it.children.map((child) => (
                        <List key={child.id} component="div" disablePadding>
                          <Link
                            to={child.url}
                            style={{ textDecoration: "none" }}
                          >
                            <ListItem
                              button
                              classes={{ root: classes.listItem }}
                              className={classes.nested}
                            >
                              <ListItemIcon
                                classes={{ root: classes.listItemIcon }}
                              >
                                <StarBorderOutlinedIcon
                                  classes={{ root: classes.listIcon }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={child.name}
                                classes={{ primary: classes.primary }}
                              />
                            </ListItem>
                          </Link>
                        </List>
                      ))
                      : null}
                  </List>
                ))}
              </div>
            </div>
          ))}
        </div>
      </ReportsContainer>
    </div>
  );
};
export default PurchaseAndSalesReports;
